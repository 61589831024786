import * as jose from 'jose'

const ID_TOKEN_KEY = 'token'

export default class JWTService {
  static getDecoded = () => {
    const jwt = window.localStorage.getItem(ID_TOKEN_KEY)
    return jose.decodeJwt(jwt)
  }
  static getTokenData = () => {
    const decoded = this.getDecoded()
    return decoded.token
  }
  static getExpiration = () => {
    const decoded = this.getDecoded()
    const isoStr = decoded.exp
    const expDate = new Date(isoStr)
    const tDate = new Date()
    return parseInt((expDate.getTime() - tDate.getTime()) / 1000)
  }

  static getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY)
  }

  static saveToken = (token) => {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
  }

  static destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY)
  }
}
