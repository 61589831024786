import JwtService from '@/services/jwt.service'
import axios from '@/plugins/axios'

export default class EdExApiService {
  constructor() {
    this.setAuthHeader()
  }

  setAuthHeader() {
    const token = JwtService.getToken()
    axios.defaults.headers.common[import.meta.env.VITE_TOKEN_HEADER_KEY] = token ? `Bearer ${token}` : ''
  }

  async getArticles() {
    const { data } = await axios.get('/v1/api_admin/articles')
    return data
  }

  async getArticle(articleId) {
    const { data } = await axios.get('/v1/api_admin/articles/' + articleId)
    return data
  }

  async articleCreate(params) {
    const { data } = await axios.post('/v1/api_admin/articles/create', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async articleUpdate(params) {
    const { data } = await axios.post('/v1/api_admin/articles/update', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async articleRemove(articleId) {
    const { data } = await axios.post('/v1/api_admin/articles/remove/' + articleId)
    return data
  }

  async getInstitutions(fields) {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    const signal = this.abortController.signal
    const { data } = await axios.get('/v1/api_admin/institutions', { signal, params: fields })
    return data
  }

  async getInstitution(institutionId) {
    const { data } = await axios.get('/v1/api_admin/institutions/' + institutionId)
    return data
  }

  async deleteInstitution(institutionId) {
    const { data } = await axios.delete('/v1/api_admin/institutions/' + institutionId)
    return data
  }

  async getCountries() {
    const { data } = await axios.get('/v1/api_admin/directory/countries')
    return data
  }

  async getCitesByCountryId(countryId) {
    const { data } = await axios.get(`/v1/api_admin/directory/countries/${countryId}/cities`)
    return data
  }

  async getLaguages() {
    const { data } = await axios.get('/v1/api_admin/directory/institution_languages')
    return data
  }

  async getStudytypes() {
    const { data } = await axios.get('/v1/api_admin/directory/studytypes')
    return data
  }

  async getEvents() {
    const { data } = await axios.get('/v1/api_admin/directory/events')
    return data
  }

  async getOrgtypes() {
    const { data } = await axios.get('/v1/api_admin/directory/orgtypes')
    return data
  }

  async getPopularSubjects() {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    const signal = this.abortController.signal
    const { data } = await axios.get('/v1/api_admin/directory/subjects/popular', { signal })
    return data
  }

  async searchSubject(name) {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    const signal = this.abortController.signal

    const { data } = await axios.get('/v1/api_admin/directory/subjects/search/' + name, { signal })
    return data
  }

  async createInstitution(params) {
    const { data } = await axios.post('/v1/api_admin/institution', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async updateInstitution(institutionId, params) {
    const { data } = await axios.post('/v1/api_admin/institution/update', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async getPopularPrograms() {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    const signal = this.abortController.signal
    const { data } = await axios.get('/v1/api_admin/directory/programs/popular', { signal })
    return data
  }

  async searchProgram(name) {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    const signal = this.abortController.signal

    const { data } = await axios.get('/v1/api_admin/directory/programs/search/' + name, { signal })
    return data
  }

  async translate(params) {
    const { data } = await axios.post('/v1/api_admin/translate', params)
    return data
  }
  async getWorkflowReviews() {
    const { data } = await axios.get('/v1/api_admin/workflow/reviews')
    return data
  }

  async getReviews() {
    const { data } = await axios.get('/v1/api_admin/reviews')
    return data
  }

  async getReview(reviewId) {
    const { data } = await axios.get('/v1/api_admin/reviews/' + reviewId)
    return data
  }

  async getReviewResponse(reviewId, params) {
    const { data } = await axios.post('/v1/api_admin/reviews/' + reviewId + '/response', params)
    return data
  }
  async reviewApprove(reviewId, params) {
    const { data } = await axios.post('/v1/api_admin/reviews/' + reviewId + '/approve', params)
    return data
  }
  async reviewReject(reviewId, params) {
    const { data } = await axios.post('/v1/api_admin/reviews/' + reviewId + '/reject', params)
    return data
  }
}
