<template>
  <div class="profile-dropdown-wrapper">
    <VaDropdown :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            <VaAvatar :size="32" color="warning"> 😍 </VaAvatar>
          </span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList>
<!--          <header v-if="group.name" class="uppercase text-[var(&#45;&#45;va-secondary)] opacity-80 font-bold text-xs px-4">-->
<!--            {{ t(`user.${group.name}`) }}-->
<!--          </header>-->
          <VaListItem
            key="Logout"
            class="menu-item px-4 text-base cursor-pointer h-8"
            @click="logout"
          >
            <VaIcon name="mso-logout" class="pr-1" color="secondary" />
            Logout
          </VaListItem>
          <VaListSeparator class="mx-3 my-2" />
        </VaList>
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  data() {
    return {
      hoverColor: '#47a6ff1a',
    }
  },
  methods: {
    logout() {
      this.$store.userStore.logout()
      this.$router.push({ name: 'login' })
    }
  }
}

</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
