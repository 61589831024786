<template>
  <RouterView />
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { useBreakpoint } from 'vuestic-ui'
import { useAppStore } from './stores/app.store'

const breakpoints = useBreakpoint()

const onResize = () => {
  const appStore = useAppStore()
  appStore.isMobile = breakpoints.smDown
  appStore.isTablet = breakpoints.mdDown
  appStore.sidebarMinimizedWidth = appStore.isMobile ? '0' : '4.5rem'
  appStore.sidebarWidth = appStore.isTablet ? '100%' : '16rem'
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    this.$store.appStore.isSidebarMinimized = true
  }
})
</script>
<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
