import JwtService from '@/services/jwt.service'
import axios from '@/plugins/axios'

export default class AuthApiService {
  constructor() {
    this.setAuthHeader()
  }

  setAuthHeader() {
    const token = JwtService.getToken()
    axios.defaults.headers.common[import.meta.env.VITE_TOKEN_HEADER_KEY] = token ? `Bearer ${token}` : ''
  }

  async auth() {
    const { data } = await axios.get('/v1/api_admin/auth')
    return data
  }

  async login(params) {
    const { data } = await axios.post('/v1/api_admin/auth/login', params)
    return data
  }
}
