import JWTService from '@/services/jwt.service'
import { defineStore } from 'pinia'
import axios from '@/plugins/axios'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    is_auth: false,
    code_hash: '',
    loading_data: false,
    loaded_data: false,
  }),
  actions: {
    async auth() {
      this.loading_data = true
      try {
        const response = await this.$api.auth.auth()
        if (Object.prototype.hasOwnProperty.call(response, 'user')) {
          this.user = response.user
          this.is_auth = true
        }
      } catch (e) {
        JWTService.destroyToken()
        axios.defaults.headers.common[import.meta.env.VITE_TOKEN_HEADER_KEY] = ''
      }
      this.loading_data = false
      this.loaded_data = true
    },

    async updateUserInfo(params) {
      this.user = await this.$api.account.updateProfile(params)
      return true
    },

    async login(params) {
      try {
        const response = await this.$api.auth.login(params)

        //TODO проверка на пароль и вернуть ошибку

        console.log(response)
        JWTService.saveToken(response.token)
        this.$api.auth.setAuthHeader()
        this.user = response.user
        this.is_auth = true
        this.loaded_data = true
      } catch (e) {}
    },

    logout() {
      this.user = null
      this.is_auth = false
      JWTService.destroyToken()
      return true
    },
  },
})
