import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: '/api/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    accept: 'application/x-www-form-urlencoded',
  },
})

axiosInstance.interceptors.response.use(
  (res) => res,
  (e) => {
    const defaultMessage = 'An error occurred when making a request to the server'
    return Promise.reject(e)
  },
)

export default axiosInstance
