import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    isMobile: false,
    isTablet: false,
    sidebarMinimizedWidth: '',
    sidebarWidth: '',
    isSidebarMinimized: false,

  }),
  actions: {

  },
})
