import resources from '@/commons/enums/resources'
import AuthApiService from '@/services/api/auth.api.service'
import EdExApiService from '@/services/api/ed-ex.api.service'

function createResources() {
  return {
    [resources.AUTH]: new AuthApiService(),
    [resources.EDEX]: new EdExApiService(),
  }
}

export { createResources }
